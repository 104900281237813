import React from "react";

import styles from "./Input.module.css";

const Input = ({ label, type, name, placeholder, onChange, value, text }) => {
  const handleKeyDown = (event) => {
    if (
      !(
        /[0-9]/.test(event.key) ||
        event.key === "Backspace" ||
        event.key === "ArrowUp" ||
        event.key === "ArrowDown"
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.inputContainer}>
      <label htmlFor={name} className={`${styles.label}`}>
        {label}
      </label>
      <div className={styles.inputWrapper}>
        <input
          required
          type={type}
          id={name}
          name={name}
          value={value}
          className={`${styles.input} `}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
        <p className={styles.currency}>{process.env.REACT_APP_TOKEN_SYMBOL}</p>
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default Input;
