import React from "react";
import Heading from "../../Component/OurPlans/Heading/Heading";
import BackButton from "../../Component/BackButton/BackButton";

import styles from "./styles.module.css";
import InvestmentAccount from "../../Component/OurPlans/InvestmentAccount/InvestmentAccount";
import AmountInvested from "../../Component/OurPlans/AmountInvested/AmountInvested";
import ActivePlan from "../../Component/OurPlans/ActivePlan/ActivePlan";
import { useState } from "react";
import TransferBalance from "../../Component/OurPlans/Popup/TransferBalance";
import { Migrate } from "../../Component/OurPlans/Popup/TransferBalance";
const OurPlans = () => {
  const [transferFunds, setTransferFunds] = useState(false);
  const [migrate, setMigrate] = useState(false);
  const [bal, setBal] = useState(0)
  return (
    <>
      <div className={styles.wrapper}>
        {/* <BackButton text="Investment" /> */}
        <Heading />
        <div className={styles.investmentInfo}>
          <InvestmentAccount setTransferFunds={setTransferFunds} setBal={setBal} setMigrate={setMigrate} />
          <AmountInvested />
        </div>
        <ActivePlan />
      </div>
      {transferFunds && <TransferBalance setPopup={setTransferFunds} balance={bal} />}
      {migrate && <Migrate setPopup={setMigrate} />}
    </>
  );
};

export default OurPlans;
