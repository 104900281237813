import React from "react";
import styles from "./ActivePlan.module.css";
import { plan1, plan2, plan3 } from "../../../images/image";
import SinglePlan from "./SinglePlan/SinglePlan";
import { getAddress } from "ethers";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
const ActivePlan = () => {
  const { address } = useAccount();
  const [data, setData] = useState([])
  const getData = async () => {
    let user = getAddress(address).toLowerCase();
    console.log(user)
    let api = "https://api.thegraph.com/subgraphs/name/civa/uns-five-levels"
    //   let query = `{
    //     onStakes(where : {user : "${user}"}) {
    //       stakeID
    //       amount
    //       blockTimestamp
    //       unlockTime
    //       apr
    //   }
    // }`
    //sort by blockTimestamp
    let query = `{
    onStakes(where : {user : "${user}"}, orderBy: blockTimestamp, orderDirection: desc) {
      stakeID
      amount
      blockTimestamp
      unlockTime
      apr
  }
}`
    const response = await axios.post(api, { query })
    setData(response.data.data.onStakes)
    console.log(response.data.data.onStakes)

  }

  useEffect(() => {
    if (!address) return
    getData()
  }, [address])
  const plans = [
    {
      image: plan1,
      id: "4u70138",
      investedAmount: 1000,
      startDate: "26 Feb, 2023 11:37 PM",
      endDate: "05 Mar, 2023 11:38 PM",
      totalReturn: 1157.5,
    }
  ];

  return (
    <section className={styles.wrapper}>
      <h5 className={styles.title}>My Stakes ({data.length})</h5>
      <div className={styles.allPlan}>
        {data.map((el, i) => (
          <SinglePlan key={i} {...el} />
        ))}
      </div>
    </section>
  );
};

export default ActivePlan;
