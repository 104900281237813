import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Header from "./Component/Header/Header";
import OurPlans from "./pages/OurPlans/OurPlans";
import LeaderBoard from "./pages/LeaderBoardPage/LeaderBoardPage";
import InvestmentHistoryPage from "./pages/InvestmentHistoryPage/InvestmentHistoryPage";
import MyStakes from "./pages/MyStakes/PlanDetailsPage";
import Login from "./pages/LoginSignUp/Login";
import SignUp from "./pages/LoginSignUp/SignUp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@rainbow-me/rainbowkit/styles.css';

import {
  getDefaultWallets,
  darkTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  zora,
  bsc,
} from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const hideComponent = location.pathname === "/login" || "/signup";
  // useEffect(() => {
  //   navigate("/");
  // }, []);


  // 

  const uns = {
    id: 343,
    name: 'UNS Chain',
    network: 'UNS',
    nativeCurrency: {
      decimals: 18,
      name: 'UNS',
      symbol: 'UNS',
    },
    rpcUrls: {
      public: { http: ['https://testnet.uns.technology'] },
      default: { http: ['https://testnet.uns.technology'] },
    },
    blockExplorers: {
      etherscan: { name: 'SnowTrace', url: 'https://testnet.unscan.io' },
      default: { name: 'SnowTrace', url: 'https://testnet.unscan.io' },
    },
    contracts: {
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 11_907_934,
      },
    },
  }

  const { chains, publicClient } = configureChains(
    [bsc],
    [
      alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
      publicProvider()
    ]
  );

  const { connectors } = getDefaultWallets({
    appName: process.env.REACT_APP_PROJECT_NAME,
    projectId: process.env.REACT_APP_PROJECT_ID,
    chains
  });

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })



  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          <Header />
          <ToastContainer />

          {hideComponent && (
            <div className="mainContainer">
              <div className="mainWrapper">
                {" "}
                <Routes>
                  <Route path="/" element={<OurPlans />} />{" "}
                  <Route path="/:ref" element={<OurPlans />} />{" "}
                  <Route path="/leaderboard" element={<LeaderBoard />} />
                  <Route path="/stakes/:id" element={<MyStakes />}></Route>
                  <Route
                    path="/history"
                    element={<InvestmentHistoryPage />}
                  ></Route>
                </Routes>

              </div>
            </div>
          )}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>

        </RainbowKitProvider >
      </WagmiConfig >

    </>
  );
}

export default App;
