import React, { useState } from "react";
import Input from "./Input/Input";
import styles from "./LoginSignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import CheckBox from "./Checkbox/CheckBox";

const SignUp = () => {
  const navigate = useNavigate();
  const [rememberMe, setMemberMe] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    fullName: "",
  });

  const inputs = [
    {
      label: "Full Name ",
      type: "text",
      name: "fullName",
      placeholder: "Enter your full name",
    },
    {
      label: "Email Address",
      type: "email",
      name: "email",
      placeholder: "Enter your email address",
    },
    {
      label: "Password ",
      type: "password",
      name: "password",
      placeholder: "Enter your passcode",
    },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>Create an Account</h2>
            <p className={styles.text}>
              Sign up with your email and get started with your free account.
            </p>
          </div>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={styles.form}
          >
            {inputs.map((input, i) => (
              <Input
                {...input}
                key={i}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <div
              className={[
                styles.rememberOrForgot,
                styles.termsAndCondition,
              ].join(" ")}
            >
              <CheckBox
                label="I have agree to the "
                checked={rememberMe}
                setChecked={setMemberMe}
              />{" "}
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={styles.termsAndCondition}
              >
                Terms & Condition
              </a>
            </div>{" "}
            <button
              className={styles.button}
              onClick={() => navigate("/plans")}
            >
              Register
            </button>
          </form>
          <div className={styles.suggetionContainer}>
            <p className={styles.suggestionText}>Already have an account?</p>
            {"  "}
            <Link to="/login" className={styles.toggle}>
              &nbsp; Sign in instead
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.overlay}></div>
    </>
  );
};

export default SignUp;
