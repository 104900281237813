import React from "react";
import styles from "./Actions.module.css";
import formatNumber from "../../utils";
const SingleAction = ({ title, value, inUsd, buttonText, action }) => {
  return (
    <div className={styles.singleAction}>
      <h4 className={styles.heading}>{title}</h4>

      <h3 className={styles.balance}>{formatNumber(value)} {process.env.REACT_APP_TOKEN_SYMBOL}</h3>
      <p className={styles.inUsd}>${inUsd}</p>
      <button
        onClick={action}
        className={styles.button}>{buttonText.toUpperCase()}</button>
    </div>
  );
};

export default SingleAction;
