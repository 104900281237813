import React from "react";
import styles from "./DataTable.module.css";
import { formatEther } from "ethers";


const SingleRow = ({
  rank,
  address,
  totalStaked,
  level1Count,
  level2Count,
  level3Count,
  level4Count,
  level5Count,
  level1Earnings,
  level2Earnings,
  level3Earnings,
  level4Earnings,
  level5Earnings,
  totalRefRewards,
  index
}) => {
  const formattedNumber = (
    number,
    showSign = true,
    currency = null,
    minimumFractionDigits = 2
  ) => {
    const sign = showSign ? (number >= 0 ? "+" : "-") : "";

    let formattedAmount = number.toFixed(Math.max(minimumFractionDigits, 2));

    if (minimumFractionDigits > 0) {
      formattedAmount = parseFloat(formattedAmount).toFixed(
        minimumFractionDigits
      );
    }

    if (currency) {
      const currencyIcon = getCurrencyIcon(currency);
      return currencyIcon + sign + formattedAmount;
    } else {
      return sign + formattedAmount;
    }
  };

  const getCurrencyIcon = (currency) => {
    const currencyIcons = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      // Add more currency codes and their respective icons as needed
    };

    return currencyIcons[currency] || "";
  };
  return (
    <>
      <tr
        onClick={() => window.open(`https://www.unsfamily.com/${address}`)}
        className={[styles.row, styles.valueContainer].join(" ")}>
        <td className={styles.item}>{index + 1}</td>

        <td
          className={styles.item}>{address && address.slice(0, 6) + "...." + address.slice(-6)}</td>
        <td className={styles.item}>{Number(formatEther(totalStaked)).toFixed(2)}</td>
        <td className={styles.item}>{level1Count}</td>
        <td className={styles.item}>{level2Count}</td>
        <td className={styles.item}>{level3Count}</td>
        <td className={styles.item}>{level4Count}</td>
        <td className={styles.item}>{level5Count}</td>

        <td className={styles.item}>{(level1Earnings)}</td>
        <td className={styles.item}>{(level2Earnings)}</td>
        <td className={styles.item}>{(level3Earnings)}</td>
        <td className={styles.item}>{(level4Earnings)}</td>
        <td className={styles.item}>{(level5Earnings)}</td>
        <td className={styles.item}>{(totalRefRewards)}</td>

      </tr>
    </>
  );
};

export default SingleRow;
