import React from "react";
import styles from "./Actions.module.css";
import SingleAction from "./SingleAction";
import { formatEther } from "ethers";
import { usePendingRewards, useWithdrawRewards, useUnstake } from "../../../hash/useContractUniversal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Actions = ({ data }) => {
  const { id } = useParams();
  const pendingRewards = usePendingRewards(process.env.REACT_APP_UNIVERSAL_CONTRACT, id);
  const [price, setPrice] = React.useState(0)

  const getUnsPrice = async () => {
    let url = "https://api.coingecko.com/api/v3/simple/price?ids=uns-token&vs_currencies=usd"
    const res = await fetch(url)
    const data = await res.json()
    setPrice((data["uns-token"].usd).toFixed(2))
  }

  const withdrawRewards = useWithdrawRewards(process.env.REACT_APP_UNIVERSAL_CONTRACT, id);
  const unstake = useUnstake(process.env.REACT_APP_UNIVERSAL_CONTRACT, id);

  React.useEffect(() => {
    getUnsPrice()
  }, [])
  const actionData = [
    {
      title: "Unclaimed Rewards",
      value: pendingRewards,
      inUsd: price * pendingRewards,
      buttonText: "Claim rewards",
      action: () => withdrawRewards(id)
    },
    {
      title: "My Stakes",
      value: (data && formatEther(data?.[1])),
      inUsd: price * (data && formatEther(data?.[1])),
      buttonText: "UNSTAKE",
      action: () => {
        if (new Date(Number(data?.[2]) * 1000).getTime() > Date.now()) return toast.error("You can't unstake before the end of the Stake Period");
        unstake(id)
      }
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Actions</h3>
      <div className={styles.boxWrapper}>
        {actionData.map((el, i) => (
          <SingleAction {...el} key={i} action={el.action} />
        ))}
      </div>
    </div>
  );
};

export default Actions;
