import { ethers, formatEther } from "ethers";
import { useEffect, useState } from "react";
import { useAccount, useContractWrite } from 'wagmi'
import { erc20ABI } from 'wagmi'
import { parseEther } from "ethers";
import { toast } from 'react-toastify'

/* global BigInt */
let rpc_url = "https://bsc.getblock.io/3231203d-1938-4db3-a8ab-3cdc0b8af374/mainnet/"
let provider = new ethers.JsonRpcProvider(rpc_url);
let abi = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "stakeID",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "unlockTime",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "apr",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "ref1",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "ref2",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "ref3",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "ref4",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "ref5",
                "type": "address"
            }
        ],
        "name": "OnStake",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "stakeID",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Rewards",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "uint256",
                "name": "stakeID",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "Unstake",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "accountCoolDown",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "allStakes",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "unlockTime",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "stakeTime",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "withdrawnAmount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "lastWithdrawTime",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "apr",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "staker",
                "type": "address"
            },
            {
                "internalType": "bool",
                "name": "status",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_stakeID",
                "type": "uint256"
            }
        ],
        "name": "calculateReward",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "cooldown",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "feeTo",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_unlockTime",
                "type": "uint256"
            }
        ],
        "name": "getAPY",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_apy",
                "type": "uint256"
            }
        ],
        "stateMutability": "pure",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_stakeID",
                "type": "uint256"
            }
        ],
        "name": "getElapsedTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_stakeID",
                "type": "uint256"
            }
        ],
        "name": "getStakeByID",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "unlockTime",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "stakeTime",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "withdrawnAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "lastWithdrawTime",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "apr",
                        "type": "uint256"
                    },
                    {
                        "internalType": "address",
                        "name": "staker",
                        "type": "address"
                    },
                    {
                        "internalType": "bool",
                        "name": "status",
                        "type": "bool"
                    }
                ],
                "internalType": "struct UniversalStake.stakes",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getStakeSize",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_unlockTime",
                "type": "uint256"
            }
        ],
        "name": "getUnlockTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "_time",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "initialize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "isInitialized",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "isPresale",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "migrate",
        "outputs": [
            {
                "internalType": "bool",
                "name": "_response",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "minimumStake",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "myTotalStakes",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "myTotalWithdrawnRewards",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "oldStakes",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "referralBonusEarned",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "referrer",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_user",
                "type": "address"
            }
        ],
        "name": "resetUserCooldown",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "rewardSchedule",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_coolDown",
                "type": "uint256"
            }
        ],
        "name": "setCoolDown",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_unlockTime",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_ref",
                "type": "address"
            }
        ],
        "name": "stake",
        "outputs": [
            {
                "internalType": "bool",
                "name": "_response",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "token",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_stakeID",
                "type": "uint256"
            }
        ],
        "name": "unstake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "useOnce",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "userStakes",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_stakeID",
                "type": "uint256"
            }
        ],
        "name": "withdrawReward",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
]

const errorLogger = (error) => {
    toast.error(error.message);

};
function useStakedAmount() {
    const { address } = useAccount();
    const [stakedAmount, setStakedAmount] = useState(0);
    useEffect(() => {
        async function getStakedAmount() {
            const provider = new ethers.JsonRpcProvider(rpc_url);
            const contract = new ethers.Contract(
                process.env.REACT_APP_UNIVERSAL_CONTRACT,
                abi,
                provider
            );
            const balance = await contract.myTotalStakes(address); //myTotalStakes
            setStakedAmount(formatEther(balance));
        }
        getStakedAmount();
    }, [address]);
    return stakedAmount;
}
function useTotalWithdrawRewards() {
    const { address } = useAccount();
    const [totalWithdrawRewards, setTotalWithdrawRewards] = useState(0);
    useEffect(() => {
        async function getTotalWithdrawRewards() {
            const provider = new ethers.JsonRpcProvider(rpc_url);
            const contract = new ethers.Contract(
                process.env.REACT_APP_UNIVERSAL_CONTRACT,
                abi,
                provider
            );
            const balance = await contract.myTotalWithdrawnRewards(address); //myTotalWithdrawnRewards
            setTotalWithdrawRewards(formatEther(balance));
        }
        getTotalWithdrawRewards();
    }, []);
    return totalWithdrawRewards;
}
function useTotalReferralEarned(addr, address) {
    const [totalReferralEarned, setTotalReferralEarned] = useState(0);
    useEffect(() => {
        async function getTotalReferralEarned() {
            const provider = new ethers.JsonRpcProvider(rpc_url);
            const contract = new ethers.Contract(
                process.env.REACT_APP_UNIVERSAL_CONTRACT,
                abi,
                provider
            );
            const balance = await contract.referralBonusEarned(address); //referralBonusEarned
            setTotalReferralEarned(formatEther(balance));
        }
        getTotalReferralEarned();
    }, [address]);
    return totalReferralEarned;
}
function usePendingRewards(address, id) {
    const [pendingRewards, setTotalReferralEarned] = useState(0);
    useEffect(() => {
        async function getTotalReferralEarned() {
            const provider = new ethers.JsonRpcProvider(rpc_url);
            const contract = new ethers.Contract(
                address,
                abi,
                provider
            );
            const balance = await contract.calculateReward(id); //referralBonusEarned
            setTotalReferralEarned(formatEther(balance));
        }
        getTotalReferralEarned();
    }, [id]);
    return pendingRewards;
}
function useGetStakeByID(address, stakeID) {
    const [stake, setStake] = useState(0);
    useEffect(() => {
        async function getStakeByID() {
            const provider = new ethers.JsonRpcProvider(rpc_url);
            const contract = new ethers.Contract(
                address,
                abi,
                provider
            );
            const balance = await contract.getStakeByID(stakeID); //userStakes
            setStake(balance);
        }
        getStakeByID();
    }, []);
    return stake;
}

function useInitiateStake(address, amount, duration, ref) {
    if (!ref) {
        ref = "0xD7E07f63A039E4f803b881BB626BaF2C0fEA32C2"
    }
    console.log(
        typeof address,
    )
    console.log(
        typeof amount,
    )

    const { write } = useContractWrite({
        address: address,
        abi: abi,
        functionName: "stake",
        args: [BigInt(parseEther(amount)), BigInt(duration), ref],
        onSuccess(data) {
            console.log("Success", data);
            provider.once(data.hash, (receipt) => {
                toast.success("Transaction Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })

        },

        onError(error) {
            console.log("Error", error);
            toast.error("an errror occured");
        },
    });
    return write
}

function useWithdrawRewards(address, id) {
    const { write } = useContractWrite({
        address: address,
        abi: abi,
        functionName: "withdrawReward",
        args: [String(id)],
        onSuccess(data) {
            provider.once(data.hash, (receipt) => {
                console.log(receipt);
                toast.success("Transaction Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
        },

        onError(error) {
            console.log("Error", error);
            toast.error("an errror occured");
        },
    });
    return write
}

function useUnstake(address, id) {
    const { write } = useContractWrite({
        address: address,
        abi: abi,
        functionName: "unstake",
        args: [id],
        onSuccess(data) {
            provider.once(data.hash, (receipt) => {
                console.log(receipt);
                toast.success("Transaction Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
        },

        onError(error) {
            console.log("Error", error);
            toast.error("an errror occured");
        },
    });
    return write
}


function useAllowance(address) {
    const [allowance, setAllowance] = useState(0);
    let token = process.env.REACT_APP_TOKEN_ADDRESS;

    let provider = new ethers.JsonRpcProvider(rpc_url);
    let contract = new ethers.Contract(
        token,
        erc20ABI,
        provider
    );

    useEffect(() => {
        let allowance = async () => {
            let allowance = await contract.allowance(address, process.env.REACT_APP_UNIVERSAL_CONTRACT);
            setAllowance(formatEther(allowance));
        }
        allowance();
    }, [address]);
    return allowance;
}


function useMigrate() {
    const { write } = useContractWrite({
        address: process.env.REACT_APP_UNIVERSAL_CONTRACT,
        abi: abi,
        functionName: "migrate",
        args: [],
        onSuccess(data) {
            provider.once(data.hash, (receipt) => {
                console.log(receipt);
                toast.success("Transaction Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
        },

        onError(error) {
            console.log("Error", error);
            toast.error("an errror occured");
        },
    });
    return write
}




export {
    useStakedAmount,
    useTotalWithdrawRewards,
    useTotalReferralEarned,
    useGetStakeByID,
    usePendingRewards,
    useInitiateStake,
    useWithdrawRewards,
    useUnstake,
    useMigrate,
    useAllowance
}