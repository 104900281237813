import React, { useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import styles from "./DropDown.module.css";

const DropDown = ({ label, dropDownItems, currentItem, setCurrenItem, setSelectedDurationId }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>{label}</p>

      <div className={styles.dropDownContaner}>
        <p
          className={styles.item}
          onClick={() => setShowDropDown((prev) => !prev)}
        >
          {currentItem}
        </p>
        {showDropDown ? (
          <AiOutlineCaretUp
            className={styles.arrow}
            onClick={() => setShowDropDown((prev) => !prev)}
          />
        ) : (
          <AiOutlineCaretDown
            className={styles.arrow}
            onClick={() => setShowDropDown((prev) => !prev)}
          />
        )}

        {showDropDown && (
          <div className={styles.dropDownItems}>
            {dropDownItems.map((el, i) => (
              <p
                className={[styles.item, styles.dropDownItem].join(" ")}
                key={i}
                onClick={() => {
                  setCurrenItem(el);
                  setShowDropDown(false);
                  setSelectedDurationId(i + 1);
                }}
              >
                {el}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;
