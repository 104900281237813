import React, { useState } from "react";
import formatNumber from "../../utils";
import Details from "./Popup/Details";
import styles from "./DataTable.module.css";
import ethers from "ethers";
import { formatEther } from "ethers";

const SingleRow = ({
  id,
  type,
  amount,
  blockTimestamp,
  index
}) => {
  const [orderDetails, setOrderDetails] = useState(false);
  return (
    <>
      <tr
        className={[styles.row, styles.valueContainer].join(" ")}
      // onClick={() => setOrderDetails(true)}
      >
        <td className={[styles.item, styles.id].join(" ")}>{index}</td>
        {/* <td className={styles.item}>{type}</td> */}
        <td className={styles.item}>{new Date(blockTimestamp * 1000).toLocaleString()}</td>
        <td className={[styles.item].join(" ")}>
          {amount ? formatEther(amount) : 0}
          <span className={styles.currency}> {process.env.REACT_APP_TOKEN_SYMBOL}</span>
        </td>

        <td
          className={[
            styles.item,
            type.toLowerCase() === "stake"
              ? styles.active
              : type.toLowerCase() === "rewards"
                ? styles.active
                : type.toLowerCase() === "completed"
                  ? styles.completed
                  : type.toLowerCase() === "unstake"
                    ? styles.cancelled
                    : "",
          ].join(" ")}
        >
          {type.toUpperCase()}
        </td>
      </tr>
      {orderDetails && <Details setPopup={setOrderDetails} />}
    </>
  );
};

export default SingleRow;
