import React from "react";
import DataTable from "../../Component/InvestmentHistory/DataTable/DataTable";
import BackButton from "../../Component/BackButton/BackButton";
import Heading from "../../Component/InvestmentHistory/Heading/Heading";
import styles from "./InvestmentHistoryPage.module.css";

const InvestmentHistoryPage = () => {
  return (
    <div className={styles.wrapper}>
      <div>
        {/* <BackButton text="Investment" /> */}
        <Heading />
      </div>
      <DataTable />
    </div>
  );
};

export default InvestmentHistoryPage;
