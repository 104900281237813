import React from "react";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <div className={styles.header}>
      <div>
        <h3 className={styles.heading}>Staking History</h3>
        <p className={styles.text}>
          Track your staking history
        </p>
      </div>
      {/* <button className={[styles.button, styles.deposit].join(" ")}>
        Stake More
      </button> */}
    </div>
  );
};

export default Heading;
