import React from "react";
// import formatNumber from "../../../utils";
import styles from "./SinglePlan.module.css";
import { formatEther } from "ethers";

const SinglePlan = ({
  stakeID,
  amount,
  blockTimestamp,
  unlockTime,
  apr,
}) => {

  let est = unlockTime
  return (
    <div className={styles.singlePlan}>
      <div className={styles.imageAndId}>
        <img src={"https://cdn-icons-png.flaticon.com/128/1055/1055648.png"} alt="#" className={styles.planImage} />
        <div className={styles.idAndInvestedAmount}>
          <p className={styles.text}>ID :{stakeID}</p>
          <p className={[styles.text, styles.secondaryText].join(" ")}>
            Amount:{" "}
            <span className={styles.amount}>
              {" "}
              {(formatEther(amount))}
            </span>{" "}
            <span className={styles.currency}>{process.env.REACT_APP_TOKEN_SYMBOL}</span>
          </p>
        </div>
      </div>
      <div className={styles.allBoxs}>
        <div className={styles.box}>
          <p className={styles.text}>{new Date(blockTimestamp * 1000).toLocaleString()}</p>
          <p className={styles.secondaryText}>Start Date</p>
        </div>{" "}
        <div className={styles.box}>
          <p className={styles.text}>{new Date(est * 1000).toLocaleString()}</p>
          <p className={styles.secondaryText}>Unstake Date</p>
        </div>
        <div className={[styles.box, styles.endDate].join(" ")}>
          <p className={styles.text}> {(Number(apr / 10000) * 365).toFixed(0)} %</p>
          <p className={styles.secondaryText}>APY</p>
        </div>
        <div className={styles.buttonContainer}>
          <button
            onClick={() => window.location.href = "/stakes" + "/" + stakeID}
            className={styles.manageButton}>MANAGE</button>
        </div>
      </div>
    </div>
  );
};

export default SinglePlan;
