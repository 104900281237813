import React from "react";
import { MdClose } from "react-icons/md";
import styles from "./Details.module.css";
import formatNumber from "../../../utils";
import { mana } from "../../../../images/image";

const Details = ({ setPopup }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <MdClose className={styles.close} onClick={() => setPopup(false)} />
        <div className={styles.padding}>
          <h5 className={styles.id}>
            Order ID <span className={styles.idNumber}>#TNX50953693</span>
          </h5>
          <div className={styles.header}>
            <div className={styles.imgAndBalance}>
              <img src={mana} alt="#" className={styles.img} />
              <div>
                <h5 className={styles.amount}>
                  {" "}
                  {formatNumber(100000.0)}{" "}
                  <span className={styles.currency}>EUR</span>{" "}
                </h5>
                <p className={[styles.text, styles.date].join(" ")}>
                  15 Feb, 2023 04:28 AM
                </p>
              </div>
            </div>
            <button className={styles.button}>Completed</button>
          </div>
        </div>
        <div className={[styles.padding].join(" ")}>
          <p className={styles.heading}>Withdraw Details</p>
          <div className={styles.grid}>
            <div>
              <p className={styles.text}>Withdraw Amount</p>
              <p className={styles.amount}>
                {formatNumber(107400.0)}{" "}
                <span className={styles.currency}> USDT</span>{" "}
              </p>
            </div>
            <div>
              <p className={styles.text}>Debited in Account</p>
              <p className={styles.amount}>
                {formatNumber(100000.0)}
                <span className={styles.currency}> EUR</span>
              </p>
            </div>
            <div>
              <p className={styles.text}>Exchange Rate</p>
              <p className={styles.amount}>
                {formatNumber(1.074)}
                <span className={styles.currency}> USDT</span>
              </p>
            </div>
            <div>
              <p className={styles.text}>Details</p>

              <p
                className={[
                  styles.text,
                  styles.currency,
                  styles.secondaryText,
                ].join(" ")}
              >
                {" "}
                Withdraw via Tether Wallet
              </p>
            </div>
          </div>
        </div>
        <div className={[styles.padding].join(" ")}>
          <p className={styles.heading}>Additional</p>
          <div className={styles.grid}>
            <div>
              <p className={styles.text}>WWithdraw Methodt</p>
              <p className={[styles.text, styles.secondaryText].join(" ")}>
                Crypto Wallet
              </p>
            </div>
            <div>
              <p className={styles.text}>Withdraw To </p>
              <p className={styles.amount}>28582...28952</p>
            </div>
            <div>
              <p className={styles.text}>Reference</p>
              <p className={styles.amount}>N/A</p>
            </div>
            <div>
              <p className={styles.text}>Updated Balance</p>

              <p className={styles.amount}>
                {" "}
                {formatNumber(41231442.33)}{" "}
                <span className={styles.currency}> EUR</span>
              </p>
            </div>
          </div>
        </div>
        <p className={[styles.padding, styles.text].join(" ")}>
          <span className={styles.text}>
            {" "}
            The transaction has been completed at
          </span>{" "}
          <span className={styles.amount}>15 Feb, 2023 04:29 AM.</span>
        </p>
      </div>
      <div className={styles.overlay} onClick={() => setPopup(false)}></div>
    </>
  );
};

export default Details;
