import React from "react";
import formatNumber from "../../utils";
import styles from "./Info.module.css";
import { formatEther } from "ethers";
import { stringify } from "viem";
import moment from "moment";
import { getAddress } from "ethers";
import { useAccount } from "wagmi";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const Info = ({ data }) => {
  const { id } = useParams();
  const { address } = useAccount();
  const [stakeData, setStakeData] = useState([])
  const getData = async () => {
    let user = getAddress(address).toLowerCase();
    console.log(user)
    let api = "https://api.thegraph.com/subgraphs/name/civa/uns-five-levels"
    //   let query = `{
    //     onStakes(where : {user : "${user}"}) {
    //       stakeID
    //       amount
    //       blockTimestamp
    //       unlockTime
    //       apr
    //   }
    // }`
    //sort by blockTimestamp
    let query = `{
    onStakes(where : {stakeID : "${id}"}, orderBy: blockTimestamp, orderDirection: desc) {
      transactionHash
  }
}`
    const response = await axios.post(api, { query })
    setStakeData(response.data.data.onStakes)
    console.log(response.data.data.onStakes)

  }

  useEffect(() => {
    if (!address) return
    getData()
  }, [address])

  let staked = data && String(data?.[1])
  // how much daily for the

  let apy = String(data?.[6]);

  let daily = ((staked * 1) * (apy / 100)) / 10000;

  return (
    <div className={styles.wrapper}>
      <div className={styles.amountContainer}>
        <div>
          <h4 className={styles.amount}>
            {data && Number(formatEther(staked)).toFixed(2)} <span className={styles.currency}>{process.env.REACT_APP_TOKEN_SYMBOL}</span>
          </h4>
          <p className={styles.text}>Staked UNS</p>
        </div>
        {/* <div>
          <h4 className={styles.amount}>157.5</h4>
          <p className={styles.text}>Profit</p>
        </div> */}
        <div className={styles.totalReturned}>
          <h4 className={styles.amount}>
            {data && formatEther(data?.[4])} <span className={styles.currency}>{process.env.REACT_APP_TOKEN_SYMBOL}</span>
          </h4>
          <p className={styles.text}>Total Withdrawn</p>
        </div>
      </div>

      <div className={styles.boxWrapper}>

        <div className={styles.spaceBetween}>
          <p className={styles.text}>Approx. Daily Earning</p>
          <p className={styles.value}>~ {data && (daily / 10 ** 18).toFixed(1)} UNS</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Estimate Yield</p>
          <p className={styles.value}>~ {data && (daily / 10 ** 18).toFixed(1) * 365} UNS</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Staked Duration</p>
          <p className={styles.value}>{moment(new Date(String(data?.[3]) * 1000)).fromNow()}</p>
        </div>{" "}
        {/* <div className={styles.spaceBetween}>
          <p className={styles.text}>Term duration</p>
          <p className={styles.value}>1 Week</p>
        </div> */}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Staker</p>
          <p className={styles.value}>
            <span className={styles.currency}></span>{" "}
            <span className={styles.value}>{String(data?.[7]).slice(0, 8) + "....." + String(data?.[7]).slice(-5)}</span>
          </p>
        </div>


        <div className={styles.spaceBetween}>
          <p className={styles.text}>APY</p>
          <p className={styles.secondaryText}>
            <span className={styles.currency}></span>{" "}
            <span className={styles.value}> {data && (Number(String(data?.[6]) / 10000) * 365).toFixed(0)} %</span>
          </p>
        </div>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Transaction Hash</p>
          <p
            onClick={() => {
              window.open(`https://bscscan.com/tx/${stakeData[0]?.transactionHash}`)
            }}
            className={styles.value}>{stakeData[0]?.transactionHash ? String(stakeData[0]?.transactionHash).slice(0, 6) + "......" + String(stakeData[0]?.transactionHash).slice(-9) : ""}</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Unstake Date</p>
          <p className={styles.value}>{data && new Date(Number(String(data?.[2])) * 1000).toLocaleString()}</p>
        </div>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Stake Date</p>
          <p className={styles.value}>{new Date(Number(String(data?.[3])) * 1000).toLocaleString()}</p>
        </div>
        {/* <div className={styles.spaceBetween}>
          <p className={styles.text}>Adjust profit</p>
          <p className={styles.value}>0 / 7 times</p>
        </div>{" "} */}
        {/* <div className={styles.spaceBetween}>
          <p className={styles.text}>Investment Status</p>
          <p className={styles.value}>Locked</p>
        </div> */}
      </div>
    </div>
  );
};

export default Info;
